<template>
  <div class="profile-view" id="myid" :style="{backgroundImage: 'url(' + plainUrl + img_url + ')'}">
    <section class="profile-view-content">
      <div class="container">
        <div class="profile-view-motto">{{motto}}</div>
        <div class="profile-view-name">{{fullname}}</div>
        <div class="profile-view-location">{{city}}</div>
        <div class="profile-view-stats">
          <ul>
            <router-link class="stats-elem" to="/biblioteka"><li>{{books_total}}<br />książek</li></router-link>
            <li class="text-center">
             <router-link class="stats-elem" to="/recenzje"><div class="d-inline-block text-left">{{reviews}}<br />recenzji</div></router-link>
            </li>
            <router-link class="stats-elem" :to="{name: 'Shelf', params: {id: readShelf}}">
              <li class="text-right">
                <div class="d-inline-block text-left">{{books_read}}<br />czyta</div>
              </li>
            </router-link>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    motto: {
      get () {
        return this.$store.state.userData.motto
      }
    },
    // name: {
    //   get () {
    //     return this.$store.state.userData.name
    //   }
    // },
    surname: {
      get () {
        return this.$store.state.userData.surname
      }
    },
    fullname: {
      get () {
        return this.$store.state.userData.fullname
      }
    },
    city: {
      get () {
        return this.$store.state.userData.city
      }
    },
    reviews: {
      get () {
        return this.$store.state.userData.reviews
      }
    },
    books_total: {
      get () {
        return this.$store.state.userData.books_total
      }
    },
    books_read: {
      get () {
        return this.$store.state.userData.books_read
      }
    },
    img_url: {
      get () {
        return this.$store.state.userData.img_url
      }
    }
  },
  async created () {
    await this.$https('/shelf', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(({ data }) => {
        for (let i = 0; i < data.response.length; i++) {
          if (data.response[i].name === 'Czytam') {
            this.readShelf = data.response[i].id
          }
        }
      })
      .catch(err => {
        console.log('', err)
      })
  },
  data () {
    return {
      readShelf: 0
    }
  }
}
</script>
<style lang="scss" scoped>
  .stats-elem {
    color: #fff;
  }
  @media screen and (max-width: 340px) {
    .profile-view .profile-view-content .profile-view-motto {
      font-size: 25px;
      line-height: 30px;
    }
  }
</style>
