<template>
  <div class="app-content-box bg-gray profile-status-info scroll-height">
    <div class="mt-auto mb-auto">
      <div class="container" v-if="public_rivalry === 1">
        <div class="top-heading-small profile-status-name text-center">
          {{name}},
        </div>
        <div v-if="gem_lvl > 0" class="top-heading-big profile-status-heading text-center">
          świetnie Ci idzie
        </div>
        <div v-else class="top-heading-big profile-status-heading text-center">
          zaangażuj się
        </div>
        <div class="top-heading-medium profile-status-heading text-center">
          Poziom {{gem_lvl}}
        </div>
      </div>
      <div class="container">
        <div class="status-icon-row-summary profile-status-summary row" v-if="current_plan !== null">
          <div class="col-4 pr-0">
            <div class="d-inline-block text-left">
              <i class="far fa-file"></i><br/>
              {{pages}} stron(y)
            </div>
          </div>
          <div class="col-4 pl-0 pr-0 text-center">
            <div class="d-inline-block text-left">
              <i class="fas fa-book"></i><br/>
              {{books_readed}} / {{books_total}} przeczytane
            </div>
          </div>
          <div class="col-4 pl-0 text-right">
            <div class="d-inline-block text-left">
              <i class="far fa-clock"></i><br/>
              {{Math.floor(read_time / 60)}} h czytania
            </div>
          </div>
        </div>
        <!-- <div class="w-100 btn-wrapper" v-if="current_plan == null">
         <router-link to='/wybierz-plan'><a href="#" class="btn-black-full w-100">Dodaj plan czytelniczy</a></router-link>
        </div> -->
      </div>
      <div v-if="public_rivalry === 1">
        <div class="profile-status-avatar-spinner swiper-container" v-if="isLoaded">
          <div class="">
            <div class="profile-status-avatar-picture">
              <img :src="thumb_url ? plainUrl + thumb_url : require('../assets/avatar-placeholder.jpg')" alt=""/>
            </div>
            <UserProfileStatusSlider :levels="levels" :gem="gem_tasks" :gem_lvl="gem_lvl" :loggedUser="true"/>
          </div>
        </div>
        <div class="container">
          <div class="profile-status-goal" :class="{completed: (lvlData.value_1 == 1)}">
            {{lvlData.label_1}}
          </div>
          <div class="profile-status-goal" :class="{completed: (lvlData.value_2 == 1)}">
            {{lvlData.label_2}}
          </div>
          <div class="profile-status-goal" :class="{completed: (lvlData.value_3 == 1)}">
            {{lvlData.label_3}}
          </div>
          <div class="profile-status-goal" v-if="lvlData.label_4.length" :class="{completed: (lvlData.value_4 == 1)}">
            {{lvlData.label_4}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileStatusSlider from './UserProfileStatusSlider'
export default {
  name: 'UserProfileStatus',
  components: {
    UserProfileStatusSlider
  },
  created () {
    this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.gem_tasks = res.data.gamification
        this.isLoaded = true
      })

    EventBus.$on('gamification-levels', (payload) => {
      this.updateLvl(payload)
    })
  },
  computed: {
    name: {
      get () {
        return this.$store.state.userData.name
      }
    },
    gem_lvl: {
      get () {
        return this.$store.state.userData.gem_lvl
      }
    },
    current_plan: {
      get () {
        return this.$store.state.userData.current_plan
      }
    },
    pages: {
      get () {
        return this.$store.state.userData.pages
      }
    },
    read_time: {
      get () {
        return this.$store.state.userData.read_time
      }
    },
    books_read: {
      get () {
        return this.$store.state.userData.books_read
      }
    },
    books_total: {
      get () {
        return this.$store.state.userData.books_total
      }
    },
    books_readed: {
      get () {
        return this.$store.state.userData.books_readed
      }
    },
    public_rivalry: {
      get () {
        return this.$store.state.userData.public_rivalry
      }
    },
    thumb_url: {
      get () {
        return this.$store.state.userData.thumb_url
      }
    }
  },
  data () {
    return {
      levels: [
        'level-1', 'level-2', 'level-3', 'level-4', 'level-5',
        'level-6', 'level-7', 'level-8', 'level-9', 'level-10',
        'level-11'
      ],
      //  avatar: 'avatar-image-big.jpg'
      gem_tasks: [],
      isLoaded: false,
      lvlData: {
        label_1: '',
        label_2: '',
        label_3: '',
        label_4: '',
        value_1: '',
        value_2: '',
        value_3: '',
        value_4: ''
      }
    }
  },
  methods: {
    updateLvl (payload) {
      this.lvlData = payload
    }
  },
  beforeDestroy () {
    EventBus.$off('gamification-levels', (payload) => {
      this.updateLvl(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .profile-status-info {
    margin-top: 0;
    padding-top: 0;

    .top-heading-medium{
      margin-bottom: 10px;
    }
  }
  .profile-status-avatar-picture {
    z-index: 10;
  }

  .app-content-box{
    min-height: auto;
  }

  .profile-status-avatar-spinner.swiper-container{
    margin: 30px 0;
    @media screen and (max-width: 320px){
      margin: 10px 0;
    }
  }

</style>
