<template>
  <div class="app-content-box bg-gray scroll-height">
    <div class="mt-auto mb-auto">
      <div class="container">
        <div class="top-heading-small text-center">Moje TOP 5</div>
      </div>
      <Tabs :activeTab="activeTab" :tabs="shelves" :componentId="'topShelves'" :friends="friends" :top="true" :shareButton="true" v-if="isLoaded">
        <div v-for="(shelf, key) in shelves" :key="key">
          <template :slot="'tab-head-' + shelf.id">
          </template>
          <template :slot="'tab-panel-' + shelf.id">
          </template>
        </div>
      </Tabs>
    </div>
  </div>
</template>
<script>
import Tabs from './Tabs'
export default {
  name: 'UserProfileTopShelves',
  components: {
    Tabs
  },
  methods: {
  },
  async created () {
    await this.$https('/shelf', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        const books = res.data.response
        books.forEach(e => {
          if (e.type === 'top') {
            this.tabs.push(e.name)
          }
        })
        books.forEach(e => {
          if (e.type === 'top') {
            this.shelves.push({
              id: e.id,
              name: e.name
            })
          }
        })
        this.activeTab = this.shelves[0].id
        this.isLoaded = true
      })
      .catch(err => {
        console.log('', err)
      })
  },
  data () {
    return {
      isLoaded: false,
      activeTab: '',
      shelves: [],
      tabs: [],
      friends: []
    }
  }
}
</script>
<style lang="scss" scoped>
  .slide-wrapper{
    min-height: 0;
  }

  .app-content-box{
    min-height: auto;
  }

  .book-heading-big{
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }
</style>
