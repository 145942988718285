<template>
  <div>
      <div class="sliding-nav">
      <ul class="nav nav-tabs" v-if="isLoaded">
        <li v-for="(tab, key) in tabs" :key="key">
          <a
            class="tab-heads"
            :class="{ active: active === tab.id, 'tab-small': small}"
            @click="switchTab(tab.id)"
            >
              <slot :name="tabHeadSlotName(tab.id)">
                {{tab.name}}
              </slot>
            </a
          >
        </li>
      </ul>
    </div>
    <div>
    <div class="tab-content">
      <div class="growing-cover-slider tab-pane-slider swiper-container">
        <div>
          <slot :name="tabPanelSlotName" v-if="this.books.length">
            <UserProfileSlider :props="books" :componentId="idToSlider" :activeShelf="active" :hideAdd="true"/>
          </slot>
          <slot :name="tabPanelSlotName" v-else>
            <EmptyShelf :top="this.top"/>
          </slot>
        </div>
        <div class="container" v-if="this.books.length">
          <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{bookData.autor}}</div></router-link>
          <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{bookData.tytul}}</div></router-link>
          <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :show-rating="false" :read-only="true"
            :rounded-corners='rounded' :rating="bookData.rating" >
            </star-rating>
          </div>
          <div class="rating-size text-center mt-2" v-if="bookData.rating > 0">
            Ocena: {{bookData.rating}}
          </div>
          <a v-if="this.shareButton" :href="'https://www.facebook.com/sharer/sharer.php?u=https://' + plainUrl + '/shelf/' + this.active + '?action=share&type=shelf&user_id=' + my_id" target="_blank">
            <p class="w-100 mt-4 mb-1 text-center">
              <i class="fab fa-telegram-plane fb-share"></i>
            </p>
          </a>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileSlider from './UserProfileSlider'
import EmptyShelf from './EmptyShelf'
import StarRating from 'vue-star-rating'
export default {
  name: 'Tabs',
  components: {
    UserProfileSlider,
    EmptyShelf,
    StarRating
  },
  props: {
    tabs: Array,
    activeTab: String,
    small: Boolean,
    selectedSlides: Array,
    activeBook: Array,
    updatevalue: Number,
    top: Boolean,
    shareButton: Boolean,
    componentId: String
  },
  watch: {
    updatevalue: function (val) {
      this.getBooks()
    }
  },
  data () {
    return {
      active: this.activeTab,
      isLoaded: false,
      selectedBooks: this.selectedSlides,
      idToSlider: this.componentId,
      bookData: { autor: '', tytul: '', rating: '', id: 0, autor_id: 0 },
      books: [],
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  created () {
    EventBus.$on('books-in-' + this.componentId, (payload) => {
      this.updateData(payload)
    })
    this.getBooks()
  },
  computed: {
    tabPanelSlotName () {
      return 'tab-panel-' + this.active
    },
    my_id () { return this.$store.state.userData.userId }
  },
  methods: {
    tabHeadSlotName (tabName) {
      return 'tab-head-' + tabName
    },
    switchTab (tabName) {
      this.active = tabName
      this.getBooks()
    },
    updateData (payload) {
      this.bookData = payload
    },
    getBooks () {
      this.$https('/shelf-books/' + this.active, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          this.books = res.data.response[0].items
          this.isLoaded = true
        })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  beforeDestroy () {
    EventBus.$off('books-in-' + this.componentId, (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .tab-small {
    font-size: 16px;
  }
  .stars-wrapper {
  display: flex;
  justify-content: center;
  }
  .rating-size {
    font-size: 14px;
  }
  .fb-share{
    width: 25px;
    height: 25px;
    color: black;
  }
  .sliding-nav .nav.nav-tabs{
    @media screen and (max-width: 320px) {
      margin: 10px 0 !important;
    }
  }
</style>
