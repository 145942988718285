<template>
  <div class="app-content-box bg-gray scroll-height">
    <div class="mb-auto mt-auto">
      <div class="container">
        <div class="top-heading-small text-center">Moja biblioteka</div>
      </div>
      <Tabs :activeTab="activeTab" :tabs="shelves" :componentId="'userProfileLibrary'" :friends="friends" v-if="isLoaded" :updatevalue="updatevalue">
        <div v-for="(shelf, key) in shelves" :key="key">
          <template :slot="'tab-head-' + shelf.id">
          </template>
          <template :slot="'tab-panel-' + shelf.id">
          </template>
        </div>
      </Tabs>
    </div>
  </div>
</template>
<script>
import Tabs from './Tabs'
export default {
  name: 'UserProfileLibrary',
  props: {
    updatevalue: Number
  },
  components: {
    Tabs
  },
  methods: {
    updateShelves () {
      this.$https('/shelf', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          const books = res.data.response
          books.forEach(e => {
            if (e.type !== 'top') {
              this.tabs.push(e.name)
            }
          })
          books.forEach(e => {
            if (e.type !== 'top') {
              this.shelves.push({
                id: e.id,
                name: e.name
              })
            }
          })
          this.activeTab = this.shelves[0].id
          this.isLoaded = true
        })
        .catch(err => {
          console.log('', err)
        })
    }
  },
  watch: {
    updatevalue: function (val) {
      this.updateShelves()
    }
  },
  async created () {
    await this.updateShelves()
  },
  data () {
    return {
      isLoaded: false,
      activeTab: '',
      shelves: [],
      tabs: [],
      friends: []
    }
  }
}
</script>
<style lang="scss" scoped>
  .slide-wrapper{
    min-height: 0;
  }

  .app-content-box{
    min-height: auto;
  }
</style>
