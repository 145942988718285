<template>
  <div class="app-content-box bg-gray scroll-height" v-if="currentReading.length && isLoaded">
    <div class="mt-auto mb-auto">
      <div class="container">
        <div class="top-heading-small text-center">Aktualnie</div>
        <div class="top-heading-big  text-center">czytane książki</div>
      </div>
        <div class="growing-cover-slider">
          <UserProfileSlider :props="currentReading" componentId="currentRead" :activeShelf="currentId" :hideAdd="true" />
          <div class="container" v-if="bookData.autor || bookData.tytul">
            <div class="profile-book-name text-center mt-3">
              <router-link :to="{name: 'Authors', params: {id: bookData.autor_id}}" class="text-dark"><div class="book-heading-small mt-2 text-center">{{bookData.autor}}</div></router-link>
              <router-link :to="{name: 'BooksDetails', params: {id: bookData.id}}" class="text-dark"><div class="book-heading-big mt-2 text-center">{{bookData.tytul}}</div></router-link>
          </div>
          <div class="stars-wrapper mt-1" v-if="bookData.rating > 0">
            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
            :border-width='borderWidth' :show-rating="false" :read-only="true"
            :rounded-corners='rounded' :rating="parseFloat(bookData.rating, 10)" >
            </star-rating>
          </div>
          <div class="rating-size text-center mt-2" v-if="bookData.rating > 0">
            Ocena: {{bookData.rating}}
          </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import EventBus from '../eventBus'
import UserProfileSlider from './UserProfileSlider'
import StarRating from 'vue-star-rating'
export default {
  name: 'UserProfileCurrent',
  components: {
    UserProfileSlider,
    StarRating
  },
  computed: {
    my_id () { return this.$store.state.my_id },

    shelf_read: {
      get () {
        return this.$store.state.userData.shelf_read
      }
    }
  },
  async beforeMount () {
    await this.$https('/shelf-books/' + this.shelf_read, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.currentReading = res.data.response[0].items
        this.currentId = res.data.response[0].id
        this.isLoaded = true
      })
      .catch(error => {
        console.log('', error)
      })
  },
  async created () {
    EventBus.$on('books-in-currentRead', (payload) => {
      this.updateData(payload)
    })
  },
  data () {
    return {
      bookData: { author: '', title: '', rating: 0, id: 0, autor_id: 0 },
      isLoaded: false,
      currentReading: [],
      currentId: 0,
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18
    }
  },
  methods: {
    updateData (payload) {
      this.bookData = payload
    }
  },
  beforeDestroy () {
    EventBus.$off('books-in-currentRead', (payload) => {
      this.updateData(payload)
    })
  }
}
</script>
<style lang="scss" scoped>
  .stars-wrapper {
    display: flex;
    justify-content: center;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
