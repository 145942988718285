<template>
    <div>
        <div v-if="!proposedLoaded || !feedLoaded">
            <Loader :dark="true" />
        </div>
        <div v-else v-show="expanded === 0" ref="feed" id="feed" :class="{'animate-intro': animationActive}" v-touch:swipe="proposedSwipeUp">
            <div v-if="fetchingFeed">
                <Loader :dark="true" />
            </div>
            <div id="details-back" :class="{'visible': sideDetailsVisible}" @click.prevent="sideDetailsVisible = false">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div id="animation-overlay" v-if="animationActive"></div>
            <div id="intro-profile-hand" v-if="animationActive" :class="{'animate': cardAnimationActive}"></div>
            <swiper ref="feedSwiper" :parallax="true" :options="swiperOptions" id="feed-swiper" @slideChange="setCardSeen()"
                    class="swiper-container swiper-container-coverflow swiper-container-3d swiper-container-initialized swiper-container-horizontal swiper-container-ios"
                    :class="{'swiper-no-swiping': lockSwiper}">
                <div class="swiper-slide">
                    <div class="feed-card profile" @click="expand()" :class="{'animate': cardAnimationActive}" v-ripple>
                        <div class="bg-wrapper" :style="{backgroundImage: 'url(' + plainUrl + userImage + ')'}">
                            <div class="profile-info">
                                <div class="container">
                                    <div class="motto">{{motto}}</div>
                                    <div class="name">{{fullname}}</div>
                                    <div class="location">{{city}}</div>
                                    <div class="stats">
                                        <ul>
                                            <div class="stats-elem">
                                                <li>{{books_total}}<br/>książek</li>
                                            </div>
                                            <li class="text-center">
                                                <div class="stats-elem">
                                                    <div class="d-inline-block text-left">{{reviews}}<br/>recenzji</div>
                                                </div>
                                            </li>
                                            <div class="stats-elem">
                                                <li class="text-right">
                                                    <div class="d-inline-block text-left">{{books_read}}<br/>czyta</div>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" v-for="slide in feedItems" :key="slide.id">
                    <div class="feed-card" :class="slide.type">
                        <div class="background" v-if="['book-rated', 'new-review'].includes(slide.type)"
                             :style="{backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(' + (slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png') + ')'}">
                            <div class="top">
                                <div class="user">
                                    <a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')" class="avatar" >
                                        <img :src="slide.user.profileImage ? plainUrl + slide.user.profileImage : require('../assets/avatar-placeholder.jpg')" alt="">
                                    </a>
                                    <p class="name"><a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')"><strong>{{slide.user.name}}</strong></a></p>
                                    <p v-if="slide.type === 'book-rated'" class="action">Ocenił książkę</p>
                                    <p v-else-if="slide.type === 'new-review'" class="action">Napisał recenzję</p>
                                </div>
                                <div class="image">
                                    <img v-if="slide.type === 'book-rated'" class="action-image action-rating" :src="require('../assets/images/feed/rating.svg')" alt="rating">
                                    <img v-else-if="slide.type === 'new-review'" class="action-image action-review" :src="require('../assets/images/feed/pencil.svg')" alt="review">
                                    <a href="#" @click.prevent="showSideDetails(slide.item.id, 'book')">
                                        <img class="cover" :src="(slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png')" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="background" v-else-if="slide.type === 'new-top'"
                             :style="{backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(' + (slide.image ? coverUrl + slide.image : coverUrl + 'no_cover.png') + ')'}">
                            <div class="top">
                                <div class="user">
                                    <a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')" class="avatar">
                                        <img :src="slide.user.profileImage ? plainUrl + slide.user.profileImage : require('../assets/avatar-placeholder.jpg')" alt="">
                                    </a>
                                    <p class="name"><a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')"><strong>{{slide.user.name}}</strong></a></p>
                                    <p class="action">Utworzył swój Top 5</p>
                                </div>
                                <div class="image">
                                    <img class="action-image action-rating" :src="require('../assets/images/feed/top.svg')" alt="top">
                                    <router-link v-if="slide.type === 'new-top'" :to="'/polka-szczegoly/' + slide.url">
                                        <img class="cover" :src="(slide.image ? coverUrl + slide.image : coverUrl + 'no_cover.png')" alt="">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="background" v-else-if="slide.type === 'new-bookface'"
                             :style="{backgroundImage: 'url(' + (slide.image ? bookfaceUrl + slide.image : coverUrl + 'no_cover.png') + ')'}">
                             <a @click.prevent="showSideDetails(slide.item.id, 'book')" class="top"></a>
                        </div>
                        <div class="background" v-else-if="slide.type === 'recommended-by-category'" :style="{backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(' + (slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png') + ')'}">
                            <div class="top">
                                <div class="action">Polecane <strong>dla Ciebie</strong></div>

                                <div class="image">
                                    <a href="#" @click.prevent="showSideDetails(slide.item.id, 'book')">
                                        <img class="cover" :src="(slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png')" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="background" v-else-if="slide.type === 'blog-article'" :style="{backgroundImage: ' url(' + (slide.image ? slide.image : coverUrl + 'no_cover.png') + ')'}">
                            <a class="top" :href="slide.url">
                                <div class="action">Blog <strong>Bookme</strong></div>
                            </a>
                        </div>

                        <div v-else-if="slide.type === 'book-recommended'" class="top" :style="{backgroundImage: 'url(' + (slide.user.profileImage ? plainUrl + slide.user.profileImage : require('../assets/avatar-placeholder.jpg')) + ')'}">
                            <div class="user">
                                <div class="name">
                                    <a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')"><strong>{{slide.user.name}}</strong></a>
                                </div>
                                <div class="action">Poleca <strong>Ci książkę</strong></div>
                            </div>
                            <div class="image">
                                <a href="#" @click.prevent="showSideDetails(slide.item.id, 'book')">
                                    <img class="cover" :src="(slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png')" alt="">
                                </a>
                            </div>
                        </div>

                        <div v-else-if="slide.type === 'bookme-special'" class="background">
                            <div class="top" :style="{backgroundImage: 'url(' + slide.user.profileImage ? plainUrl + slide.user.profileImage : require('../assets/avatar-placeholder.jpg') + ')'}">
                                <div class="user">
                                    <div class="name-bookme">
                                        <a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')"><strong>Bookme</strong></a>
                                    </div>
                                    <div class="action">Specjalna pozycja <strong>dla Ciebie</strong></div>
                                </div>
                                <div class="image">
                                    <a href="#" @click.prevent="showSideDetails(slide.item.id, 'book')">
                                        <img class="cover" :src="(slide.item.cover ? coverUrl + slide.item.cover : coverUrl + 'no_cover.png')" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="slide.type === 'bookme-top'" class="background">
                            <router-link :to="'/polka-szczegoly/' + slide.url">
                                <div class="top" :style="{backgroundImage: 'url(' + require('../assets/images/top-temp.png') + ')'}"></div>
                            </router-link>
                        </div>

                        <a v-else-if="slide.type === 'advertisement'" :href="slide.url" class="top" :style="{ backgroundImage: 'url(' + (slide.image ? plainUrl + slide.image : '') + ')' }">
                        </a>

                        <div v-else class="top">
                            <img v-if="slide.type === 'create-reading-plan'" class="shape" :src="require('../assets/images/feed/shape_arrows_reverse.png')" alt="books">
                            <img v-if="slide.type === 'create-your-library'" class="shape" :src="require('../assets/images/feed/shape_yellow.png')" alt="books">
                            <img v-if="slide.type === 'share-books'" class="shape" :src="require('../assets/images/feed/shape_green.png')" alt="books">
                            <img v-if="slide.type === 'write-first-review'" class="shape" :src="require('../assets/images/feed/shape_orange.png')" alt="books">
                        </div>

                        <div class="bottom">
                            <div v-if="slide.type === 'create-reading-plan'" class="description">
                                <p><strong>Czytaj świadomie</strong></p>
                                <p>Stwórz swój pierwszy</p>
                                <p>Plan Czytelniczy.</p>
                            </div>

                            <div v-else-if="slide.type === 'create-your-library'" class="description">
                                <p><strong>Stwórz swoją bibliotekę</strong></p>
                                <p>użyj skanera idodaj książki</p>
                                <p>do swoich półek.</p>
                            </div>

                            <div v-else-if="slide.type === 'share-books'" class="description">
                                <p><strong>Dziel się książkami</strong></p>
                                <p>Zaproś przyjaciół do Bookme</p>
                            </div>

                            <div v-else-if="slide.type === 'write-first-review'" class="description">
                                <p><strong>Napisz pierwszą recenzję</strong></p>
                                <p>Wybierz książkę i podziel się</p>
                                <p>swoją opinią z innymi</p>
                            </div>

                            <div v-else-if="slide.type === 'advertisement'" class="description">
                                <a :href="slide.url">
                                    <p v-html="slide.description"></p>
                                </a>
                            </div>

                            <div v-else-if="slide.type === 'blog-article'" class="description">
                                <a :href="slide.url" target="_blank">
                                    <p v-html="slide.description"></p>
                                </a>
                            </div>

                            <div v-else-if="slide.type === 'bookme-top'" class="description">
                                <router-link class="text-white" :to="'/polka-szczegoly/' + slide.url">
                                    <p class="title"><strong>Bookme Top 5</strong></p>
                                    <p class="author">{{slide.description}}</p>
                                </router-link>
                            </div>

                            <div v-else-if="slide.type === 'new-top'" class="description">
                                <p class="title">
                                    <a :href="'/polka-szczegoly/' + slide.url"><strong>{{slide.description}}</strong></a>
                                </p>
                            </div>

                            <div v-else-if="slide.type === 'new-bookface'" class="description">
                                <p class="title">
                                    <a href="#" @click.prevent="showSideDetails(slide.user.id, 'user')"><strong>{{slide.user.name}}</strong><br> utworzył <strong>#Bookface</strong></a>
                                </p>
                            </div>

                            <div v-else class="description">
                                <p class="title">
                                    <a href="#" @click.prevent="showSideDetails(slide.item.id, 'book')"><strong>{{slide.item.title}}</strong></a>
                                </p>
                                <p class="author">{{slide.item.author}}</p>
                            </div>

                            <div v-if="slide.type === 'create-reading-plan'" class="buttons justify-content-center">
                                <router-link class="link-button" :to="{name: 'TrainingPlanChoose'}">Stwórz teraz</router-link>
                            </div>

                            <div v-else-if="slide.type === 'create-your-library'" class="buttons justify-content-center">
                                <router-link class="link-button" to="/wyszukiwanie">Stwórz teraz</router-link>
                            </div>

                            <div v-else-if="slide.type === 'share-books'" class="buttons justify-content-center">
                                <router-link class="link-button" :to="{name: 'MessegesNotification', params: {tab: 'invites'}}">Zaproś teraz</router-link>
                            </div>

                            <div v-else-if="slide.type === 'write-first-review'" class="buttons justify-content-center">
                                <router-link class="link-button" to="/biblioteka">Napisz teraz</router-link>
                            </div>

                            <div v-else class="buttons">
                                <a class="like" @click.prevent="saveFeedCard(slide)" :class="{'selected': slide.saved}" href="#"></a>
                                <div v-if="slide.type === 'advertisement'" class="center-text"><a class="text-white" :href="slide.url">Sponsorowane</a></div>
                                <a v-if="slide.type === 'bookme-top' || slide.type === 'advertisement'" class="share" href="#" @click="share(slide)"></a>
                                <router-link v-else to="/powiadomienia" class="message"></router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-slide" v-if="shouldFeedLoadMore">
                    <div class="feed-card info" @click="this.loadMoreCards" style="display: flex; justify-content: center; align-items: center;">
                        <div class="load-cards">
                            <img :src="require('../assets/images/feed/feed_check.svg')" alt="">
                        </div>
                        <div class="info-title" v-if="showUserUpToDate">Jesteś na bieżąco!</div>
                        <div class="info-subtitle" v-if="showUserUpToDate">Wyświetlono wszystkie nowe posty.</div>
                        <div class="info-action" v-if="showUserUpToDate"><span>Zobacz starsze posty</span></div>
                        <div class="info-action" v-else><span>Zobacz kolejne posty</span></div>
                    </div>
                </div>
                <div class="swiper-slide" v-else>
                    <div class="feed-card info" @click="this.goToSlide" style="display: flex; justify-content: center; align-items: center;">
                        <div class="feed-reload-icon">
                            <img :src="require('../assets/images/feed/feed_reload.svg')" alt="">
                        </div>
                        <div class="info-title">Nie masz więcej postów</div>
                        <div class="info-subtitle">Poczytaj książkę!</div>
                        <div class="info-action">albo <span>wróć do początku</span></div>
                    </div>
                </div>
            </swiper>

            <div id="proposed" ref="proposed" v-if="!this.sideDetailsVisible" :class="{'animate': proposedAnimationActive}">
                <div class="handle">
                    <div id="intro-proposed-hand" v-if="animationActive" :class="{'animate': proposedAnimationActive}"></div>
                </div>
                <div class="top-bar" v-touch:swipe="proposedSwipeDown">
                    <h3 class="text-center" :class="{'opacity-full': proposedVisible}">
                        <span v-if="!proposedFiltersVisible">Polecamy</span>
                        <span v-else>Filtruj</span>
                    </h3>
                    <a class="filter" :class="{'opacity-full': proposedVisible}" @click="toggleProposedFilters" href="#">
                        <img :src="require('../assets/images/feed/filter.svg')" alt="">
                    </a>
                </div>

                <transition name="fade">
                    <div id="proposed-filters" v-if="proposedFiltersVisible" class="content bg-white">
                        <div class="filter-list">
                            <div v-for="(filter, i) in proposedFilters" :key="i"><a @click.prevent="filterProposed(filter)" href="#" :class="{'active': proposed.label === filter}">{{ filter }}</a></div>
                        </div>
                    </div>
                </transition>

                <div class="content bg-white" ref="proposedContent">
                    <div class="book-list">
                        <div v-for="(item, i) in proposed.items" :key="item.id" class="book">
                            <router-link :to="'/ksiazka/' + item.id" v-if="proposed.type === 'book'">
                                <transition name="warp" >
                                    <div v-if="proposedVisible" :style="{'--i': i}">
                                        <div class="book-cover-container">
                                            <div class="book-cover">
                                                <img :src="item.image ? coverUrl + item.image : coverUrl + 'no_cover.png'" alt="">
                                            </div>
                                            <div class="bottom-plus-sign" @click.prevent="openModal(item)"><i class="fas fa-plus-circle"></i></div>
                                        </div>
                                        <div>
                                            <a href="" class="book-author">{{item.autor}}</a>
                                            <span class="book-title">{{item.tytul}}</span>
                                        </div>
                                    </div>
                                </transition>
                            </router-link>
                            <router-link :to="'/profil/' + item.id" v-else-if="proposed.type === 'person'">
                                <transition name="warp">
                                    <div v-if="proposedVisible" :style="{'--i': i}">
                                        <div class="book-cover-container">
                                            <div class="book-cover">
                                                <img style="border-radius: 50%;" :src="item.thumb_url ? plainUrl + item.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="">
                                            </div>
                                        </div>
                                        <div>
                                            <a href="" class="book-author text-center">{{item.username}}</a>
                                        </div>
                                    </div>
                                </transition>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <transition name="slide">
                <div id="feed-side-details" v-if="this.sideDetailsVisible">
                    <BookDetails v-if="currentDetailType === 'book'" :id="currentDetailId" :feed="true"/>
                    <OtherUserProfile v-else-if="currentDetailType === 'user'" :id="currentDetailId"/>
                </div>
            </transition>
        </div>

        <ModalSlot title="Dodaj książkę" v-if="isPopupShown" @close-modal="closeModal">
            <div class="text-center mb-2 modal-main-title">Dodaj książkę</div>
            <div class="modal-wrapper">
                <div class="growing-slide-cover">
                    <img :src="( modal_cover ? coverUrl + modal_cover : coverUrl + 'no_cover.png')" class="slide-cover-image" />
                </div>
                <div class="book-heading-big text-center mt-3">{{modal_author}}</div>
                <div class="book-heading-small text-center mt-2">{{modal_title}}</div>
            </div>
            <router-link :to="{name: 'BorrowBook', params: {id: item.id}}"><button class="btn-black-border w-100 mt-3 mb-1">Dodaj na półkę</button></router-link>
            <button class="btn-black-border w-100 mt-3 mb-1" @click="saveToLater(item.id)">Zapisz na później</button>
            <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
        </ModalSlot>
    </div>
</template>
<script>
import { Swiper } from '../../node_modules/vue-awesome-swiper'
import EventBus from '../eventBus'
import Loader from '../components/Loader'
import BookDetails from '../views/BooksDetails'
import OtherUserProfile from '../views/OtherUserProfile'
import ModalSlot from './ModalSlot'
import { plainUrl } from '../conf/api'

export default {
  name: 'Feed',
  components: {
    Swiper,
    Loader,
    BookDetails,
    OtherUserProfile,
    ModalSlot
  },
  props: {

  },
  data () {
    return {
      slideIndex: 0,
      expanded: 0,
      proposedVisible: false,
      proposedFiltersVisible: false,
      lockSwiper: false,
      proposed: [],
      proposedLoaded: false,
      feedLoaded: false,
      firstVisit: false,
      animationActive: false,
      cardAnimationActive: false,
      proposedAnimationActive: false,
      proposedReceived: [],
      proposedFilters: [],
      sideDetailsVisible: false,
      currentDetailId: null,
      currentDetailType: null,
      isPopupShown: false,
      fetchingFeed: false,
      feedItems: [],
      feedPage: 1,
      shouldFeedLoadMore: true,
      showUserUpToDate: false,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: false,
        centeredSlides: false,
        slidesPerView: '1',
        simulateTouch: true,
        followFinger: false,
        speed: 500,
        coverflowEffect: {
          rotate: 0,
          stretch: '41%',
          depth: 100,
          modifier: 2,
          slideShadows: false
        }
      },
      plainUrl: plainUrl
    }
  },
  computed: {
    userId: {
      get () {
        return this.$store.state.userData.user_id
      }
    },
    userImage: {
      get () {
        return this.$store.state.userData.img_url
      }
    },
    motto: {
      get () {
        return this.$store.state.userData.motto
      }
    },
    fullname: {
      get () {
        return this.$store.state.userData.fullname
      }
    },
    city: {
      get () {
        return this.$store.state.userData.city
      }
    },
    reviews: {
      get () {
        return this.$store.state.userData.reviews
      }
    },
    books_total: {
      get () {
        return this.$store.state.userData.books_total
      }
    },
    books_read: {
      get () {
        return this.$store.state.userData.books_read
      }
    }
  },
  mounted () {
    EventBus.$emit('feed-visible', false)
  },
  async created () {
    await this.getProposed()
    await this.getFeed()
    EventBus.$on('show-feed', this.retract)
    EventBus.$on('go-to-first-card', this.goToSlide)
  },
  destroyed: function () {
    EventBus.$emit('feed-hidden', false)
  },
  watch: {
    expanded () {
      if (this.expanded === 1) {
        EventBus.$emit('feed-hidden', false)
        this.$parent.scrollEnabled = true
      }
      if (this.expanded === 0) {
        EventBus.$emit('feed-visible', false)
        this.$parent.scrollEnabled = false
        this.$parent.resetSections()
      }
    }
  },
  methods: {
    getProposed () {
      this.$https('/proposed', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          const proposedResponse = res.data.response
          proposedResponse.forEach(element => {
            this.proposedFilters.push(element.label)
            this.proposedReceived.push(element)
          })
          this.proposed = this.proposedReceived.find(x => x.label === 'Książki')
          this.proposedLoaded = true
        })
        .catch(err => {
          console.log(err, '')
        })
    },

    filterProposed (filter) {
      const items = this.proposedReceived.find(x => x.label === filter)
      this.$refs.proposedContent.scrollTo(0, 0)
      this.proposed = items
      this.proposedFiltersVisible = false
    },

    proposedSwipeUp (direction) {
      if (this.proposedVisible === false && this.sideDetailsVisible === false) {
        if (direction === 'top') {
          this.$refs.proposed.classList.add('visible')
          this.proposedVisible = true
        }
      }
    },

    proposedSwipeDown (direction) {
      if (this.proposedVisible === true) {
        if (direction === 'bottom') {
          this.$refs.proposed.classList.remove('visible')
          this.$refs.proposedContent.scrollTo(0, 0)
          this.proposedVisible = false
          this.proposedFiltersVisible = false
        }
      }
    },

    getFeed () {
      if (this.fetchingFeed === false) {
        this.fetchingFeed = true
        this.$https('/feed/get-feed?page=' + this.feedPage, {
          method: 'GET',
          mode: 'no-cors',
          crossDomain: true
        })
          .then(res => {
            const items = res.data.response.items
            items.forEach(element => {
              this.feedItems.push(element)
            })
            this.firstVisit = res.data.response.firstVisit
            this.feedLoaded = true
            this.feedPage = this.feedPage + 1

            if (res.data.response.actionsRemaining <= 0) {
              this.shouldFeedLoadMore = false
            }

            this.showUserUpToDate = res.data.response.userUpToDate

            this.$nextTick(function () {
              if (this.feedLoaded === true && this.proposedLoaded && this.firstVisit === true) {
                this.animateIntro()
              }
            })
            this.fetchingFeed = false
          })
          .then(() => {
            this.setCardSeen()
          })
          .catch(err => {
            console.log(err, '')
          })
      }
    },

    setCardSeen () {
      this.slideIndex = this.$refs.feedSwiper.$swiper.activeIndex - 1
      const el = this.feedItems[this.slideIndex]
      if (typeof el !== 'undefined') {
        if (el.action_id) {
          const data = 'action_id=' + el.action_id
          this.$https('/feed/set-action-seen', {
            method: 'POST',
            mode: 'no-cors',
            data: data,
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(res => {

            })
            .catch(err => {
              console.log(err, '')
            })
        }
      }
    },

    async loadMoreCards () {
      if (this.shouldFeedLoadMore) {
        this.getFeed()
      }
    },

    expand () {
      if (this.expanded === 0) {
        this.lockSwiper = true
        this.$nextTick(function () {
          this.$refs.feed.classList.add('exp')
          this.$refs.proposed.classList.remove('visible')
          window.setTimeout(() => {
            this.expanded = 1
          }, 500)
        })
      }
    },

    retract () {
      if (this.expanded === 1) {
        this.expanded = 0
        this.lockSwiper = false
        this.$nextTick(() => {
          this.$refs.feed.classList.remove('exp')
          this.$refs.proposed.classList.remove('visible')
        })
      }
    },

    saveFeedCard (item) {
      item.saved = !item.saved
      const saveData = 'from_user=' + item.user.id +
          '&type=' + item.type +
          '&item_id=' + item.item.id +
          '&description=' + item.description +
          '&url=' + item.url +
          '&image=' + item.image +
          '&save=' + (item.saved === 1 ? 0 : 1)
      this.$https('/feed/save-feed-item', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        data: saveData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {

        })
        .catch(err => {
          console.log(err, '')
        })
    },

    showSideDetails (loadId, type) {
      this.currentDetailType = type
      this.currentDetailId = loadId
      this.sideDetailsVisible = true
    },

    goToSlide () {
      this.sideDetailsVisible = false
      if (typeof this.$refs.proposed !== 'undefined') {
        this.$refs.proposed.classList.remove('visible')
      }
      this.proposedVisible = false
      this.proposedFiltersVisible = false
      if (typeof this.$refs.feedSwiper !== 'undefined') {
        if (this.slideIndex !== -1) {
          this.$refs.feedSwiper.$swiper.slideTo(0, 500, false)
        } else {
          this.expand()
        }
      }
    },

    toggleProposedFilters () {
      this.proposedFiltersVisible = !this.proposedFiltersVisible
    },

    animateIntro () {
      this.proposedVisible = true
      this.animationActive = true
      this.$nextTick(function () {
        window.setTimeout(() => {
          this.cardAnimationActive = true
          window.setTimeout(() => {
            this.proposedAnimationActive = true
            window.setTimeout(() => {
              this.animationActive = false
              this.cardAnimationActive = false
              this.proposedAnimationActive = false
              this.proposedVisible = false
              this.firstVisit = false
            }, 3000)
          }, 2700)
        }, 500)
      })
    },

    openModal (item) {
      this.item = item
      this.modal_cover = item.image
      this.modal_title = item.tytul
      this.modal_author = item.autor
      this.isPopupShown = true
    },

    closeModal () {
      this.isPopupShown = false
    },

    saveToLater (id) {
      const getData = 'status=later&own=1&borrowed=0'
      this.$https('book/' + id + '/add/' + 0, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.closeModal()
        this.$emit('updated-later')
      })
        .catch(error => {
          console.log('', error)
        })
    },
    share (slide) {
      const url = slide.type === 'advertisement' ? encodeURI(slide.url) : encodeURI('https://' + this.plainUrl + '/shelf/' + slide.url)
      window.location.href = 'gonative://share/sharePage?url=' + url
    }
    // async download () {
    //   const url = encodeURI('https://' + plainUrl + '/img/books/643774i.jpg')
    //   await (window.location.href = 'gonative://share/downloadFile?url=' + url)
    //     .then(
    //       window.location.href = 'instagram://story-camera'
    //     )
    // }
  }
}
</script>
<style lang="scss" scoped>
    #proposed-filters{
        position: absolute;
        top: 104px;
        left: 0;
        z-index: 21;

        .filter-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            div{
                width: 50%;
                padding: 10px;

                a{
                    color: #000;
                    font-weight: 500;

                    &.active{
                        color: #1f1f1f;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    #animation-overlay{
        position: absolute;
        top: -55px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: transparent;
        z-index: 100;
    }

    #intro-profile-hand{
        position: absolute;
        top: 30vh;
        z-index: 40;
        right: 20%;
        opacity: 0;
        width: 28vw;
        height: 28vw;
        background: rgba(0, 0, 0, 0.5) url('../assets/images/feed/intro_hand_white_left.png') no-repeat center center;
        background-size: 60%;
        border-radius: 50%;
    }

    #feed #proposed.animate{
        #intro-proposed-hand{
            position: absolute;
            top: 0;
            z-index: 40;
            left: calc(50% - 14vw);
            opacity: 0;
            width: 28vw;
            height: 28vw;
            background: rgba(0, 0, 0, 0.5) url('../assets/images/feed/intro_hand_white_up.png') no-repeat center center;
            background-size: 60%;
            border-radius: 50%;
        }
        .top-bar{
            h3, .filter{
                transition: none;
                transition-delay: 0s;
            }
        }

        .warp-enter-active, .warp-leave-active {
            transition: transform 0s, opacity 0s;
        }

        .warp-enter-to, .warp-leave {
            transition-delay: 0s;
        }
    }

    .warp-enter-active, .warp-leave-active {
        transition: transform .8s, opacity 1s;
    }

    .warp-enter, .warp-leave-to {
        opacity: 0;
        transform: translateY(100%);
        transition-timing-function: ease-in;
    }

    .warp-enter-to, .warp-leave {
        opacity: 1;
        transform: translateX(0);
        transition-timing-function: ease-out;
        transition-delay: calc(0.2s * var(--i));
    }

    @keyframes move-slide-card {
        0% { transform: translateX(0)}
        25% { transform: translateX(-30px)}
        50% { transform: translateX(0)}
        75% { transform: translateX(-30px)}
        100% { transform: translateX(0)}
    }

    @keyframes move-proposed {
        0% { transform: none}
        25% { transform: translateY(-50vh)}
        75% { transform: translateY(-50vh)}
        90% { transform: none}
    }

    @keyframes slide-intro-hand {
        0% { opacity: 0 }
        12.5% { opacity: 1 }
        25% { opacity: 0; transform: translateX(-50vw) }
        50% { opacity: 0; transform: none }
        62.5% { opacity: 1 }
        75% { opacity: 0; transform: translateX(-50vw) }
        100% { opacity: 0 }
    }

    @keyframes slide-proposed-hand {
        0% { opacity: 0; transform: translateY(-20vh) }
        25% { opacity: 1; transform: translateY(0) }
        37.5% { opacity: 0.8 }
        50% { opacity: 0.5; transform: translateY(5px) }
        62.5% { opacity: 0.3 }
        75% { opacity: .3; transform: translateY(-2px) }
        90% { opacity: .5; transform: translateY(-10vh) }
        100% { opacity: 0; transform: translateY(-10vh) }
    }

    #feed.animate-intro .feed-card.profile.animate{
        animation: move-slide-card 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    #feed.animate-intro #intro-profile-hand.animate{
        animation: slide-intro-hand 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    #feed.animate-intro #proposed.animate{
        animation: move-proposed 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    #feed.animate-intro #intro-proposed-hand.animate{
        animation: slide-proposed-hand 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .modal-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .load-cards, .feed-reload-icon{
        width: 23vw;
        margin-bottom: 30px;

        img{
            width: 100%;
        }
    }

    .feed-card.info{
        font-family: 'Roboto';

        .info-title{
            font-size: 20px;
            line-height: 1.59;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .info-subtitle{
            font-size: 17px;
            font-weight: 300;
            margin-bottom: 30px;
        }

        .info-action{
            font-size: 17px;
            font-weight: 300;

            span{
                text-decoration: underline;
            }
        }
    }

    .loader-wrapper {
        margin-top: -55px;
    }

    #feed .loader-wrapper{
        margin-top: -20px;
        height: calc(100vh - 113px);
    }

    .slide-cover-image{
        max-width: 100%;
    }
</style>
