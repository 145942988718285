<template>
    <div>
      <div v-if="isLoading && !showFeed" class="app-content-box bg-gray">
        <Loader :dark="true" />
      </div>
      <div ref="profilePage" v-touch:swipe="handleScroll" v-else-if="!isLoading" style="padding-bottom: 60px; padding-top: 53px">
        <Feed v-show="showFeed" />
        <Home />
<!--        <UserProfileRecommended @proposedupdate="updateFriendsList"/>-->
        <BookfaceSlider v-if="bookfaces.length" :bookfaces="bookfaces" :count="bookfaces.length" :label="'Twoje #Bookface'" :sliderType="'profile'" />
        <UserProfileStatus />
        <UserProfileTopShelves />
        <UserProfileCurrent />
        <UserProfileLibrary :updatevalue="counter"/>
      </div>
    </div>
</template>
<script>
import Home from './Home'
import Loader from '../components/Loader'
import UserProfileStatus from '../components/UserProfileStatus'
import UserProfileCurrent from '../components/UserProfileCurrent'
import UserProfileLibrary from '../components/UserProfileLibrary'
import Feed from '../components/Feed'
import BookfaceSlider from '../components/BookfaceSlider'
// import UserProfileRecommended from '../components/UserProfileRecommended'
import UserProfileTopShelves from '../components/UserProfileTopShelves'
import { scroller } from 'vue-scrollto/src/scrollTo'
import EventBus from '../eventBus'
export default {
  name: 'UserProfile',
  components: {
    Home,
    Loader,
    UserProfileStatus,
    UserProfileCurrent,
    UserProfileLibrary,
    // UserProfileRecommended,
    UserProfileTopShelves,
    Feed,
    BookfaceSlider
  },
  data () {
    return {
      isLoading: true,
      counter: 0,
      scroller: scroller(),
      scrollEnabled: false,
      currentSection: 1,
      nextSection: 2,
      previousSection: 0,
      bookfaces: [],
      bookfacePage: 1,
      bookfacesTotal: 0,
      fetchingBookfaces: false,
      loadMoreBookfaces: true,
      userId: 0
    }
  },
  async created () {
    await this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.$store.commit('updateUserId', res.data.id)
        this.$store.commit('updateUserFullname', res.data.username)
        this.$store.commit('updateUserName', res.data.fname)
        this.$store.commit('updateUserSurname', res.data.lname)
        this.$store.commit('updateUserEmail', res.data.email)
        this.$store.commit('updateUserCity', res.data.city)
        this.$store.commit('updateUserMotto', res.data.motto)
        this.$store.commit('updateUserImg', res.data.img_url)
        this.$store.commit('updateUserThumb', res.data.thumb_url)
        this.$store.commit('updateUserGemLvl', res.data.gem_lvl)
        this.$store.commit('updateUserCurrentPlan', res.data.current_plan)
        this.$store.commit('updateUserCurrentRead', res.data.current_read)
        this.$store.commit('updateUserBooksRead', res.data.books_read)
        this.$store.commit('updateUserBooksReaded', res.data.books_readed)
        this.$store.commit('updateUserBooksTotal', res.data.books_total)
        this.$store.commit('updateUserLendBooks', res.data.lend_books)
        this.$store.commit('updateUserNotifyComments', res.data.notify_comments)
        this.$store.commit('updateUserNotifyLibrary', res.data.notify_library)
        this.$store.commit('updateUserNotifyLikes', res.data.notify_likes)
        this.$store.commit('updateUserNotifyMessage', res.data.notify_message)
        this.$store.commit('updateUserPages', res.data.pages_raeded)
        this.$store.commit('updateUserReadTime', res.data.read_time)
        this.$store.commit('updateUserPoints', res.data.points)
        this.$store.commit('updateUserPublicProfile', res.data.public_profile)
        this.$store.commit('updateUserPublicRivalry', res.data.public_rivalry)
        this.$store.commit('updateUserSharedBooks', res.data.shared_books)
        this.$store.commit('updateUserShelfRead', res.data.shelf_read)
        this.$store.commit('updateUserShowLibrary', res.data.show_library)
        this.$store.commit('updateUserIsBookshop', res.data.is_bookshop)
        this.$store.commit('updateUserReviews', res.data.reviews)
        this.$store.commit('updateUserShowFeed', true)
        if (typeof window.osData !== 'undefined') {
          this.$store.commit('updateUserOneSignalId', window.osData.oneSignalUserId)
        }
        this.userId = res.data.id
        this.getBookfaces()
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
    if (typeof window.osData !== 'undefined') {
      const requestData = 'player_id=' + this.$store.state.userData.one_signal_id + '&is_mobile=1'
      await this.$https('/push-subscribe', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: requestData
      })
        .then(res => {

        })
        .catch(error => {
          console.log('', error.data.message)
        })
    }
    this.scrollEnabled = !this.showFeed
    EventBus.$on('reset-scroll', this.resetSections)
    EventBus.$on('feed-hidden', this.enableScroll)
    EventBus.$on('feed-visible', this.disableScroll)
    // EventBus.$on('load-more-bookfaces', this.getBookfaces)
    // EventBus.$on('bookface-updated', this.enableScroll)
  },
  computed: {
    showFeed: {
      get () {
        return this.$store.state.userData.show_feed
      }
    }
  },
  mounted () {
    document.body.classList.add('no-scroll')
  },
  destroyed () {
    document.body.classList.remove('no-scroll')
  },
  methods: {
    getBookfaces () {
      if (!this.fetchingBookfaces) {
        this.fetchingBookfaces = true
        const data = new FormData()
        data.append('id', this.userId)
        data.append('type', 'user')
        data.append('page', this.bookfacePage)
        this.$https.post('/bookface/get-bookfaces', data, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then(res => {
            // const items = res.data.response.items
            // this.bookfacesTotal = res.data.response.total
            this.bookfaces = res.data.response
            // if (this.bookfaces.length >= this.bookfacesTotal) {
            //   this.loadMoreBookfaces = false
            // }
            //
            // if (this.loadMoreBookfaces) {
            //   this.disableScroll()
            //   items.forEach(element => {
            //     this.bookfaces.push(element)
            //   })
            //   this.bookfacePage = this.bookfacePage + 1
            //   EventBus.$emit('update-swiper', false)
            // }
            this.fetchingBookfaces = false
          })
          .catch(error => {
            console.log('', error)
          })
      }
    },
    updateFriendsList () {
      this.counter = this.counter + 1
    },
    handleScroll (direction) {
      if (this.scrollEnabled === true) {
        if (direction === 'top') {
          if (this.nextSection < this.$refs.profilePage.children.length) {
            this.scroller(this.$refs.profilePage.children[this.nextSection], 500, { cancelable: false, offset: -55, onStart: () => { this.disableScroll() }, onDone: () => { this.sectionsUp() } })
          }
        }
        if (direction === 'bottom') {
          if (this.previousSection > 0) {
            this.scroller(this.$refs.profilePage.children[this.previousSection], 500, { cancelable: false, offset: -55, onStart: () => { this.disableScroll() }, onDone: () => { this.sectionsDown() } })
          }
        }
      }
    },
    resetSections () {
      this.currentSection = 1
      this.nextSection = 2
      this.previousSection = 0
    },
    sectionsUp () {
      this.nextSection++
      this.currentSection++
      this.previousSection++
      this.enableScroll()
    },
    sectionsDown () {
      this.nextSection--
      this.currentSection--
      this.previousSection--
      this.enableScroll()
    },
    disableScroll () {
      this.scrollEnabled = false
    },
    enableScroll () {
      this.scrollEnabled = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box{
    height: calc(100vh - 115px);
    padding-top: 25px;
    padding-bottom: 25px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 320px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.scroll-height{
      min-height: calc(100vh - 115px);
      max-height: calc(100vh - 115px);
    }
  }

</style>
